<template>
    <v-row no-gutters>
        <v-col cols="12">
            <v-row justify="center" class="py-5 px-10 mb-8 mt-2">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">

                    <v-card class="pa-0">
                        <v-app-bar color="green darken-2" dark flat dense>
                            <v-app-bar-title>Create Webhook Receiver</v-app-bar-title>
                            <!-- <v-spacer/>
                            <v-btn icon @click="createNewItemDialog = true">
                                <font-awesome-icon :icon="['fas', 'plus']" style="font-size: 20px;" fixed-width></font-awesome-icon>
                            </v-btn> -->
                        </v-app-bar>

                        <v-card-text>
                            <p>A webhook receiver is used to receive push notifications from Unicorn Springs about accounts, users, orders, etc.</p>
                            <!-- <v-btn style="background-color: green darken-2; color: #ffffff;" elevation="2" @click="createNewItemDialog = true">
                                Create
                            </v-btn> -->
                        </v-card-text>

                        <v-form @submit.prevent="createItem" @keyup.enter.native.prevent="createItem" class="px-2">
                            <v-text-field v-model="newReceiverLabel" label="Label" hint="A name for this webhook receiver" ref="newReceiverLabelInput" outlined dense></v-text-field>
                            <v-text-field v-model="newReceiverURL" label="URL" hint="Where to send messages" ref="newReceiverURLInput" outlined dense></v-text-field>
                            <v-text-field v-model="newReceiverPriority" label="Priority" hint="1 is highest" ref="newReceiverPriorityInput" outlined dense></v-text-field>
                        </v-form>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn elevation="4" class="green white--text" @click="createItem" :disabled="!isNewItemFormComplete">
                                <span>Create</span>
                            </v-btn>
                            <!-- <v-btn text color="grey" @click="createNewItemDialog = false">
                                <span>Cancel</span>
                            </v-btn> -->
                            <v-spacer></v-spacer>
                        </v-card-actions>
                    </v-card>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<style scoped>

</style>

<script>
import { mapState } from 'vuex';
import { isValidURL } from '@/sdk/input.js';

export default {
    data: () => ({
        organization: null,

        submitFormTimestamp: null,

        newReceiverLabel: null,
        newReceiverPriority: '10',
        newReceiverURL: null,
    }),
    computed: {
        ...mapState({
            session: (state) => state.session,
            productTypeChoices: (state) => state.productTypeChoices,
            focus: (state) => state.focus,
        }),
        isNewItemFormComplete() {
            return typeof this.newReceiverLabel === 'string' && this.newReceiverLabel.length > 0 && typeof this.newReceiverPriority === 'string' && this.newReceiverPriority.length > 0 && typeof this.newReceiverURL === 'string' && isValidURL(this.newReceiverURL);
        },
    },
    watch: {
        focus() {
            this.$activateInput('newReceiverLabelInput'); // TODO: if this is filled in already, focus on next field, etc
        },
    },
    methods: {
        async loadOrganization() {
            try {
                this.error = false;
                this.$store.commit('loading', { loadOrganization: true });
                const response = await this.$client.organization(this.$route.params.organizationId).currentOrganization.get();
                console.log(`organization/dashboard.vue: response ${JSON.stringify(response)}`);
                if (response) {
                    this.organization = response;
                } else {
                    // TODO: redirect back to organization list? show a not found message?
                }
            } catch (err) {
                console.error('failed to load organization', err);
                this.error = true;
            } finally {
                this.$store.commit('loading', { loadOrganization: false });
            }
        },
        async createItem() {
            if (Number.isInteger(this.submitFormTimestamp) && this.submitFormTimestamp + 500 > Date.now()) {
                return;
            }
            this.submitFormTimestamp = Date.now();
            try {
                this.$store.commit('loading', { createItem: true });
                let priority;
                const priorityInput = this.newReceiverPriority;
                if (typeof priorityInput === 'string') {
                    try {
                        priority = Number.parseInt(priorityInput, 10);
                    } catch (err) {
                        this.$bus.$emit('snackbar', { type: 'error', headline: 'Priority must be a non-negative integer' }); // TODO: should be a message next to the input
                    }
                } else if (typeof priorityInput === 'number') {
                    priority = Math.round(priorityInput);
                }
                const item = {
                    label: this.newReceiverLabel,
                    priority,
                    url: this.newReceiverURL,
                };
                const response = await this.$client.organization(this.$route.params.organizationId).webhookReceiver.create(item);
                if (response?.isCreated && response.id) {
                    this.$bus.$emit('snackbar', { type: 'success', headline: 'OK' });
                    this.$router.push({ name: 'organization-edit-webhook-receiver', params: { organizationId: this.$route.params.organizationId }, query: { id: response.id, t: Date.now() } });
                } else {
                    this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed' });
                }
            } catch (err) {
                console.error('createItem failed', err);
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed' });
            } finally {
                this.$store.commit('loading', { createItem: false });
            }
        },
    },
    mounted() {
        this.loadOrganization();
        this.$nextTick(() => {
            setTimeout(() => { this.$activateInput('newReceiverLabelInput'); }, 1);
        });
    },
};
</script>
